/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import clientJson from "../../../package.json";
import FabricVersion from "../../FabricVersion";

const styles = (theme) => {
  const { type } = theme.palette;
  const dark = type === "dark";
  return {
    root: {
      margin: "2%",
    },
    footer: {
      backgroundColor: dark ? "#5e558e" : "#e8e8e8",
      color: dark ? "#ffffff" : undefined,
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
    },

    logo: {
      width: 260,
      height: 50,
      "@media (max-width: 1415px) and (min-width: 990px)": {
        width: 200,
        height: 40,
      },
    },
  };
};

const FooterView = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <div className={classes.footer}>
        <div style={{ align: "left" }}>
          <img
            className={classes.logo}
            src="https://www.astrakode.tech/wp-content/themes/astrakode/img/astra-logo.svg"
          />
        </div>
        <div style={{ textAlign: "center" }}>
          {"Hyperledger Explorer Client Version: "}
          {clientJson.version}
          &emsp;
          {"Fabric Compatibility: "} {FabricVersion.map((v) => v)}
        </div>
      </div>
    </div>
  </div>
);

export default withStyles(styles)(FooterView);
