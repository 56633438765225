import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,

  //Alert
} from "@material-ui/core";
import { Table, Card, CardBody, CardTitle } from "reactstrap";

export const Observer = ({
  funcName,
  fetchedFunctions,
  setFunctionParameters,
  inputObj, // accept inputObj as prop
}) => {
  const [funcInput, setFuncInput] = useState([]);

  useEffect(() => {
    let func = { inputs: [] };
    if (fetchedFunctions && funcName !== "none") {
      func = fetchedFunctions.find((f) => f.name === funcName) || func;
    }
    setFuncInput(func.inputs);
  }, [funcName, fetchedFunctions]);

  return (
    <div>
      {funcInput.length > 0 ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 style={{ color: "#4d90fe" }}>Function parameters</h3>
        </div>
      ) : (
        ""
      )}
      <Table hover responsive>
        <tbody>
          {funcInput.map((input, index) => (
            <tr key={index}>
              <td>
                <TextField
                  id={`outlined-${input.name}`}
                  label={`${input.name} (${input.type})`}
                  variant="outlined"
                  value={inputObj[input.name] || ""} // set the value from inputObj
                  onChange={(event) =>
                    setFunctionParameters(event.target.value, input.name)
                  }
                  style={{ width: "300px", margin: "10px" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
