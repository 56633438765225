import React, { Component } from "react";
import Button from "@material-ui/core/Button";

class MyButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
    };
  }



  handleMouseOver = () => {
    this.setState({ hovered: true });
  };

  handleMouseOut = () => {
    this.setState({ hovered: false });
  };

  handleClick = (e) => {
    if (this.props.onClick) {
      
      this.props.onClick(e); // Execute the onClick function passed from parent
    }
  };

  render() {
    const { hovered } = this.state;
    const   buttonStyle = {
      margin: "10px",
      backgroundColor: hovered ? "white" : "#7fa9ff",
      color: hovered ? "#7fa9ff" : "white",
      border: "2px solid #7fa9ff",
    };
    return (
      <Button
        variant="contained"
        onClick={this.handleClick} 
        style={buttonStyle}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default MyButton;
