/**
 *    SPDX-License-Identifier: Apache-2.0
 */
import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import { Observer } from "./Observer";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,

  //Alert
} from "@material-ui/core";
import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ViewTable from "../Styled/ViewTable";
import FontAwesome from "react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Table, Card, CardBody, CardTitle } from "reactstrap";
import { transactionType } from "../types";
import ButtonAkb from "../AkbCustomComponents/ButtonAkb";

/* eslint-disable */
const dark = false;
/* eslint-enable */
const styles = (theme) => ({
  listIcon: {
    color: "#ffffff",
    marginRight: 20,
  },
  JSONtree: {
    "& ul": {
      backgroundColor: "transparent !important",
      color: "#fff",
    },
  },
  readset_null: {
    display: "none",
  },
  fullwidth: {
    width: "100%",
    marginTop: 105,
    backgroundColor: "#f0f5f9",
    paddingBottom: "100px",
  },
  display: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    marginTop: 50,
    backgroundColor: "transparent",
  },
  displayModal: {
    display: "grid",
    marginLeft: "auto",
    marginRight: "auto",
    height: "600px",
    width: "400px",
    marginTop: 50,
    backgroundColor: "white",
    justifyContent: "center",
  },
  alert: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    marginTop: 50,
    backgroundColor: "transparent",
  },
  card: {
    color: "#ffffff",
    backgroundColor: "##ffeeff",
  },
  title: {
    textAlign: "center",
    backgroundColor: dark ? "#242036" : "#004d6b",
    color: "#ffffff",
    font: "bold 30px",
    fontWeight: 400,
    letterSpacing: 3,
    paddingTop: "1%",
    margin: 0,
  },
  dialog: {
    borderStyle: "solid",
    borderColor: "#d8d6d6",
    borderWidth: 2,
    filter: "drop-shadow(0 0 0.75rem #d8d6d6)",
    backgroundColor: dark ? "#453e68" : undefined,
    color: dark ? "#ffffff" : undefined,
  },
  formControlRow: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginBottom: "20px",
  },

  body: {
    backgroundColor: dark ? "#ffffff" : undefined,
    color: dark ? "#ffffff" : undefined,
    "& > h1": {
      fontSize: "25pt",
    },
  },
  copy: {
    display: "none",
    color: dark ? "#ffffff" : undefined,
  },
  copied: {
    display: "none",
    color: dark ? "#ffffff" : undefined,
  },
  copyBtn: {
    float: "right",
    borderColor: "transparent",
    color: dark ? "#ffffff" : "##ffffff",
    backgroundColor: "transparent",
    cursor: "pointer",
    textShadow: "1px 1px 2px rgba(129, 129, 129, 0.753)",
    "&:hover": {
      color: dark ? "#ffffff" : "#2153f8",
    },
    "&:focus": {
      outline: "none",
    },
    "&:hover $copy": {
      display: "block",
      position: "absolute",
      padding: 0,
      backgroundColor: "transparent",
      fontSize: 10,
      marginTop: -10,
    },
    "&:active $copy": {
      display: "none",
    },
    "&:active $copied": {
      display: "block",
      position: "absolute",
      padding: 0,
      backgroundColor: "transparent",
      marginTop: -30,
    },
  },
  closeBtn: {
    float: "right",
    borderColor: "transparent",
    color: "#8b0000",
    backgroundColor: "transparent",
    margin: "-12px 10px 3px 0px",
    fontSize: 25,
    height: 30,
    cursor: "pointer",
    filter: "drop-shadow(0 0 0.75rem #D8D6D6)",
    "&:focus": {
      outline: "none",
      color: "#c00404",
    },
  },
  newOperationSection: {
    display: "flex",
    alignItems: "center", // align items vertically
    justifyContent: "flex-start", // align items horizontally to the start
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: "#f0f5f9", // this should match your Run Chaincode Function background
    marginTop: theme.spacing(4),
  },
  operationMenuContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240, // Set a minimum width or adjust as needed
  },
  setParameterButton: {
    margin: theme.spacing(1),
  },
  modalStyle: {
    borderRadius: 30,
    border: "2px solid #7fa9ff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    maxHeight: "80%",
    overflowY: "auto",
  },
});

const apiUrl = window.REACT_APP_API_URL;

export class InvokeView extends Component {
  state = {
    openLoaderModal: false,
    openCCParams: false,
    functionName: "none",
    showSuccessMessage: false,
    selectedUser: "",
    selectedChaincode: "",
    executedFunctionName: "",
    successMessage: "",
    showErrorMessage: false,
    functions2Render: [],
    errorMessage: "",
    resultData: null,
    inputValues: {},
    httpMethod: "POST",
    fetchedFunctions: {},
    orgName: "",
    channelName: "",
    orgType: "",
    orgName: "",
    couchDbPort: "",
    caName: "",
    caNameCu: "",
    caPort: "",
    networkMode: "down",
    nodeNames: "",
    nodePort1: "",
    nodeChaincodePort1: "",
    consortiumName: "",
    channelName: "",
    channelCreator: "",
    ordererOrg: "",
    ordererNode: "",
    numMembers: "",
    selectedOrg: "",
    selectedNode: "",
    joinChannelName: "",
    joinOrgName: "",
    joinPeer1: "",
    joinPeer2: "",
    newNodeOrgName: "",
    newNodeName: "",
    newNodePort: "",
    newCcPort: "",
    openAddOrgModal: false,
    openJoinChannelModal: false,
    openAddNodeModal: false,
    openAddChannelModal: false,
    selectedOperation: "Add New Org",
    reqBody: {
      CC_NAME: "",
      CC_VERSION: "",
      CC_SRC_PATH: "",
      INIT_FUNCTION_NAME: "",
      INIT_REQUIRED: false,
    },
  };

  // ================== BUSINESS LOGIC ======================
  runChaincodeFunction = async (e) => {
    e.preventDefault();
    this.setState({ openLoaderModal: true });

    const {
      functionName,
      inputObj,
      selectedUser,
      selectedChaincode,
      orgName,
      channelName,
    } = this.state;

    let requestBody = {
      functionName: functionName,
      arguments: inputObj,
      userId: selectedUser,
      chaincodeName: selectedChaincode,
      orgName: orgName,
      channelName: channelName,
    };

    fetch(`${apiUrl}/call-chaincode-function`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          openLoaderModal: true,
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: data.message,
          resultData: JSON.stringify(data.result),
          executedFunctionName: this.functionName,
        });
      })
      .catch((error) => {
        this.setState({
          openLoaderModal: true,
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            (error.message && `${error.message} - ${error.error}`) ||
            error.error ||
            "An unknown error occurred",
          resultData: null,
        });
      });
  };

  handleModalOpen = () => {
    switch (this.state.selectedOperation) {
      case "Add New Org":
        this.toggleModal("openAddOrgModal", true);
        break;
      case "Add New Channel":
        this.toggleModal("openAddChannelModal", true);
        break;
      case "Join Channel":
        this.toggleModal("openJoinChannelModal", true);
        break;
      case "Add New Node":
        this.toggleModal("openAddNodeModal", true);
        break;
      default:
        break;
    }
  };

  renderFunctions = () => {
    return this.state.functions2Render.map((func) => (
      <MenuItem key={func.name} value={func.name}>
        {func.name}
      </MenuItem>
    ));
  };

  runAddNodeScript = async () => {
    const { newNodeOrgName, newNodeName, newNodePort, newCcPort } = this.state;

    // Preparing the answers as an array directly
    const answersList = [newNodeOrgName, newNodeName, newNodePort, newCcPort];

    const requestBody = {
      filename: "network.sh",
      mode: "newNodeServer",
      answers: answersList,
    };

    fetch(`${apiUrl}/run-network-script`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: data.message,
        });
      })
      .catch((error) => {
        this.setState({
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
        });
      });
  };

  createUser = async () => {
    const requestBody = {
      userId: this.state.selectedUser,
      orgName: this.state.orgName,
      caName: this.state.caNameCu,
    };

    fetch(`${apiUrl}/create-user`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: data.message,
          resultData: data.result,
        });
      })
      .catch((error) => {
        this.setState({
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
        });
      });
  };

  fetchFunctionNames = async () => {
    // Check if the chaincode name is empty
    if (
      !this.state.selectedChaincode ||
      this.state.selectedChaincode.trim() === ""
    ) {
      // Update the state to show an error message and to not display any previously fetched functions
      this.setState({
        showErrorMessage: true,
        errorMessage: "Please fill in the name of the chaincode first.",
        functions2Render: [],
      });

      // Do not proceed with the fetch call
      return;
    }
    // Prepare the request body with the chaincode name from the state
    const requestBody = {
      chaincodeName: this.state.selectedChaincode,
    };

    fetch(`${apiUrl}/get-json`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: "Functions fetched correctly",
          fetchedFunctions: data,
          functions2Render: data.invokeFunctions,
        });
      })
      .catch((error) => {
        this.setState({
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
        });
      });
  };

  runAddChannelScript = async () => {
    const {
      consortiumName,
      channelName,
      channelCreator,
      ordererOrg,
      ordererNode,
      numMembers,
      selectedOrg,
      selectedNode,
    } = this.state;

    // Preparing the answers as an array directly
    const answersList = [
      consortiumName,
      channelName,
      channelCreator,
      ordererOrg,
      ordererNode,
      numMembers,
      selectedOrg,
      selectedNode,
    ];

    const requestBody = {
      filename: "network.sh",
      mode: "newChannelServer",
      answers: answersList,
    };

    fetch(`${apiUrl}/run-network-script`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: "Script executed succesfully",
        });
      })
      .catch((error) => {
        this.setState({
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
        });
      });
  };

  runAddNewOrgScript = async () => {
    const {
      orgType,
      orgName,
      useCouchDb,
      couchDbPort,
      caName,
      caPort,
      nodeNames,
      nodePort1,
      nodeChaincodePort1,
    } = this.state;

    let answersList;

    if (useCouchDb) {
      answersList = [
        orgType,
        orgName,
        "y",
        couchDbPort,
        caName,
        caPort,
        nodeNames,
        nodePort1,
        nodeChaincodePort1,
      ];
    } else {
      answersList = [
        orgType,
        orgName,
        "n",
        caName,
        caPort,
        nodeNames,
        nodePort1,
        nodeChaincodePort1,
      ];
    }

    const requestBody = {
      filename: "network.sh",
      mode: "newOrgServer",
      answers: answersList,
    };

    fetch(`${apiUrl}/run-network-script`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: "Script executed succesfully",
        });
      })
      .catch((error) => {
        this.setState({
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
        });
      });
  };

  setChainCodeParameters = async () => {
    fetch(`${apiUrl}/update-chaincode-params`, {
      method: "POST",
      body: JSON.stringify({ updates: this.state.reqBody }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: "Parameters updated succesfully",
        });
      })
      .catch((error) => {
        this.setState({
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
        });
      });
  };

  handleHttpMethodChange = (event) => {
    const newMethod = event.target.value;
    let f2r =
      newMethod == "POST"
        ? this.state.fetchedFunctions.invokeFunctions
        : this.state.fetchedFunctions.queryFunctions;
    if (f2r) {
      this.setState({
        httpMethod: newMethod,
        functions2Render: f2r,
      });
    } else {
      this.setState({
        showErrorMessage: true,
        errorMessage: "Fetch functions first",
      });
    }
  };

  runNetworkMode = async () => {
    let reqBody = {
      mode: this.state.networkMode,
      filename: "network.sh",
    };

    fetch(`${apiUrl}/run-network-script`, {
      method: "POST",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: "Script executed succesfully",
        });
      })
      .catch((error) => {
        this.setState({
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
        });
      });
  };

  runChaicodeMain = async () => {
    let reqBody = {
      channelName: this.state.mainChannelName,
    };

    fetch(`${apiUrl}/run-chaincode-main`, {
      method: "POST",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: "Chaincode deployed succesfully",
        });
      })
      .catch((error) => {
        this.setState({
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
        });
      });
  };

  runJoinChannelScript = async () => {
    const { joinChannelName, joinOrgName, joinPeer1, joinPeer2 } = this.state;

    // Concatenate the input values into a single string separated by commas
    const answersList = [joinChannelName, joinOrgName, joinPeer1, joinPeer2];
    const concatenatedAnswers = answersList.join(",");

    // Construct the request body with the concatenated answers
    const requestBody = {
      filename: "network.sh",
      mode: "join",
      answers: concatenatedAnswers,
    };

    fetch(`${apiUrl}/run-network-script`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, parse the JSON error object and reject it
          return response.json().then((errorData) => Promise.reject(errorData));
        }
        // If the response is ok, parse the JSON success object
        return response.json();
      })
      .then((data) => {
        this.setState({
          showSuccessMessage: true,
          showErrorMessage: false,
          successMessage: "Script executed succesfully",
        });
      })
      .catch((error) => {
        this.setState({
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            error.message || error.error || "An unknown error occurred",
        });
      });
  };

  renderAlerts = () => {
    const { classes } = this.props;
    return (
      <div>
        {this.state.showSuccessMessage && (
          <>
            <Alert severity="success" className={classes.alert}>
              {this.state.successMessage}
            </Alert>
            <Alert severity="info" className={classes.alert}>
              <pre>{this.state.resultData}</pre>
            </Alert>
          </>
        )}
        {this.state.showErrorMessage && (
          <Alert severity="error" className={classes.alert}>
            {this.state.errorMessage}
          </Alert>
        )}
      </div>
    );
  };

  // ============================  STATE MANAGEMENT =======================================
  // Generic function to handle state changes
  handleInputChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  // Function to handle modal toggles
  toggleModal = (modalName, isOpen) => {
    this.setState({ [modalName]: isOpen });
  };

  setReqBodyField = (field, event) => {
    event.persist();
    this.setState((prevState) => ({
      reqBody: {
        ...prevState.reqBody,
        [field]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }));
  };

  // ============================ SETSTATE WITH LOGIC ============================
  handleClose = () => {
    this.setState({
      openLoaderModal: false,
      resultData: null,
    });
    this.clearAlerts();
  };

  clearAlerts = () => {
    this.setState({
      showSuccessMessage: false,
      showErrorMessage: false,
      successMessage: "",
      errorMessage: "",
    });
  };

  setFunctionParameters = (value, name) => {
    let newInputObj = { ...this.state.inputObj };
    newInputObj[name] = value;

    this.setState({
      inputObj: newInputObj,
    });
  };

  setUseCouchDb = (e) => {
    const val = this.state.useCouchDb ? !this.state.useCouchDb : true;
    this.setState({ useCouchDb: val });
  };

  setFunctionToExecute = (event) => {
    const newFunctionName = event.target.value;

    this.setState({
      functionName: newFunctionName,
      functionToExecute: newFunctionName,
      inputValues: {},
      inputObj: {},
    });
  };

  onCloseLoaderModal = () => {
    this.setState({
      openLoaderModal: false,
      resultData: false,
    });
    this.clearAlerts();
  };

  // ============================ render() ============================
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.fullwidth}>
        {/* ================== Eventual alerts ==================*/}
        {this.renderAlerts()}
        {/* ================== Run Chaincode Function ==================*/}
        <h1 className={classes.display} style={{ color: "#5170d4" }}>
          Run Chaincode Function
        </h1>
        <Alert className={classes.display} severity="info">
          Select Chaincode Function to Execute
        </Alert>
        <ViewTable>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "20px",
            }}
          >
            {/* Labels for columns */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ color: "#4d90fe" }}>Function selection</h3>
            </div>

            <div style={{ display: "flex", gap: "20px" }}>
              {/* Left Column - Text Fields */}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <TextField
                  label="User"
                  variant="outlined"
                  value={this.state.selectedUser}
                  onChange={(event) =>
                    this.setState({ selectedUser: event.target.value })
                  }
                  fullWidth
                />
                <TextField
                  label="Chaincode"
                  variant="outlined"
                  value={this.state.selectedChaincode}
                  onChange={(event) =>
                    this.setState({ selectedChaincode: event.target.value })
                  }
                  fullWidth
                />
                <TextField
                  label="Org Name"
                  variant="outlined"
                  value={this.state.orgName}
                  onChange={(event) =>
                    this.setState({ orgName: event.target.value })
                  }
                  fullWidth
                />
                <TextField
                  label="Channel Name"
                  variant="outlined"
                  value={this.state.channelName}
                  onChange={(event) =>
                    this.setState({ channelName: event.target.value })
                  }
                  fullWidth
                />
              </div>

              {/* Right Column - Function Selection */}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <ButtonAkb onClick={this.fetchFunctionNames} fullWidth>
                  Fetch Function
                </ButtonAkb>
                <FormControl fullWidth>
                  <InputLabel id="http-method-select-label">
                    HTTP Method
                  </InputLabel>
                  <Select
                    labelId="http-method-select-label"
                    id="http-method-select"
                    value={this.state.httpMethod}
                    onChange={this.handleHttpMethodChange}
                  >
                    <MenuItem value="POST">INVOKE</MenuItem>
                    <MenuItem value="GET">QUERY</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="function-select-label">Function</InputLabel>
                  <Select
                    labelId="function-select-label"
                    id="function-select"
                    value={this.state.functionName}
                    onChange={this.setFunctionToExecute}
                  >
                    {this.renderFunctions()}
                  </Select>
                </FormControl>
              </div>
            </div>

            {/* Observer Component */}
            <Observer
              funcName={this.state.functionName}
              fetchedFunctions={this.state.functions2Render}
              setFunctionParameters={this.setFunctionParameters}
              inputObj={this.state.inputObj}
            />

            {/* Modal (unchanged) */}
            {this.state.openLoaderModal && (
              <Modal
                open={true}
                onClose={this.onCloseLoaderModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className={classes.dialog}>
                  <Card className={classes.card}>
                    <CardTitle className={classes.title}>
                      Executing Function
                      <button
                        type="button"
                        onClick={this.handleClose}
                        className={classes.closeBtn}
                      >
                        <FontAwesome name="close" />
                      </button>
                    </CardTitle>
                    <CardBody className={classes.body}>
                      {this.state.showSuccessMessage && (
                        <Alert severity="success" className={classes.alert}>
                          {this.state.successMessage}
                        </Alert>
                      )}
                      {this.state.resultData && (
                        <Alert severity="info" className={classes.alert}>
                          <pre
                            style={{
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-word",
                            }}
                          >
                            {JSON.stringify(this.state.resultData, null, 2)}
                          </pre>
                          <CopyToClipboard
                            text={JSON.stringify(
                              this.state.resultData,
                              null,
                              2
                            )}
                            onCopy={() => this.setState({ copied: true })}
                          >
                            <Button>Copy to Clipboard</Button>
                          </CopyToClipboard>
                          {this.state.copied ? (
                            <span style={{ color: "green" }}>Copied.</span>
                          ) : null}
                        </Alert>
                      )}
                      {this.state.showErrorMessage && (
                        <Alert severity="error" className={classes.alert}>
                          {this.state.errorMessage}
                        </Alert>
                      )}
                      {!this.state.showSuccessMessage &&
                        !this.state.showErrorMessage && (
                          <CircularProgress color="grey" />
                        )}
                      <ButtonAkb
                        variant="contained"
                        style={{ marginTop: "20px" }}
                        onClick={this.onCloseLoaderModal}
                      >
                        Close
                      </ButtonAkb>
                    </CardBody>
                  </Card>
                </div>
              </Modal>
            )}

            {/* Execute Function Button */}
            <ButtonAkb onClick={this.runChaincodeFunction} fullWidth>
              Execute Function
            </ButtonAkb>
          </div>
        </ViewTable>
        {/* ================== New Operation ==================*/}
        <h1 className={classes.display} style={{ color: "#5170d4" }}>
          New Operation
        </h1>
        <Alert className={classes.display} severity="info">
          Select an operation from the menu and set the parameters.
        </Alert>
        <ViewTable className={classes.newOperationSection}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <div className={classes.operationMenuContainer}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="operation-select-label" shrink={true}>
                  Select Operation
                </InputLabel>
                <Select
                  labelId="operation-select-label"
                  id="operation-select"
                  value={this.state.selectedOperation}
                  onChange={this.handleInputChange("selectedOperation")}
                  label="Select Operation"
                >
                  <MenuItem value="Add New Org">Add New Org</MenuItem>
                  <MenuItem value="Add New Channel">Add New Channel</MenuItem>
                  <MenuItem value="Join Channel">Join Channel</MenuItem>
                  <MenuItem value="Add New Node">Add New Node</MenuItem>
                  {/* Add more MenuItems as per your available operations */}
                </Select>
              </FormControl>
              <ButtonAkb onClick={this.handleModalOpen}>
                Set Parameters
              </ButtonAkb>
            </div>
          </div>
        </ViewTable>
        <Modal
          open={this.state.openAddOrgModal}
          onClose={() => {
            this.clearAlerts();
            this.toggleModal("openAddOrgModal", false);
          }}
          aria-labelledby="add-org-modal-title"
          aria-describedby="add-org-modal-description"
        >
          <div className={classes.modalStyle}>
            <h2 id="add-org-modal-title" style={{ color: "#7fa9ff" }}>
              {" "}
              Add New Organization Parameters
            </h2>
            <TextField
              label="ORGANIZATION TYPE"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={this.handleInputChange("orgType")}
            />
            <TextField
              label="ORGANIZATION NAME"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={this.handleInputChange("orgName")}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
              }}
            >
              <Checkbox
                checked={this.state.useCouchDb}
                onChange={this.setUseCouchDb}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span>use CouchDb</span>
            </div>
            {this.state.useCouchDb && (
              <TextField
                label="COUCHDB PORT"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={this.handleInputChange("couchDbPort")}
              />
            )}
            <TextField
              label="CA_NAME"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={this.handleInputChange("caName")}
            />
            <TextField
              label="CA_PORT"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={this.handleInputChange("caPort")}
            />
            <TextField
              label="NODE NAMES"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={this.handleInputChange("nodeNames")}
            />
            <TextField
              label="Node PORT"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={this.handleInputChange("nodePort1")}
            />
            <TextField
              label="CHAINCODE PORT"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={this.handleInputChange("nodeChaincodePort1")}
            />
            <ButtonAkb
              onClick={this.runAddNewOrgScript}
              style={{ margin: "20px" }}
            >
              Add Organization
            </ButtonAkb>
            {this.renderAlerts()}
          </div>
        </Modal>
        <Modal
          open={this.state.openAddChannelModal}
          onClose={() => {
            this.clearAlerts();
            this.toggleModal("openAddChannelModal", false);
          }}
          aria-labelledby="add-channel-modal-title"
          aria-describedby="add-channel-modal-description"
        >
          <div className={classes.modalStyle}>
            <h2 id="add-channel-modal-title" style={{ color: "#7fa9ff" }}>
              Add New Channel Parameters
            </h2>
            <div id="add-channel-modal-description"></div>
            <TextField
              label="ConsortiumName"
              variant="outlined"
              onChange={this.handleInputChange("consortiumName")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="ChannelName"
              variant="outlined"
              onChange={this.handleInputChange("channelName")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="ChannelCreator"
              variant="outlined"
              onChange={this.handleInputChange("channelCreator")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="OrdererOrg"
              variant="outlined"
              onChange={this.handleInputChange("ordererOrg")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="OrdererNode"
              variant="outlined"
              onChange={this.handleInputChange("ordererNode")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="NumMembers"
              variant="outlined"
              onChange={this.handleInputChange("numMembers")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="SelectedOrg"
              variant="outlined"
              onChange={this.handleInputChange("selectedOrg")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="SelectedNode"
              variant="outlined"
              onChange={this.handleInputChange("selectedNode")}
              fullWidth
              margin="normal"
            />
            <ButtonAkb
              onClick={this.runAddChannelScript}
              style={{ marginTop: "20px" }}
            >
              Run Script
            </ButtonAkb>
            {this.renderAlerts()}
          </div>
        </Modal>
        <Modal
          open={this.state.openJoinChannelModal}
          onClose={() => {
            this.clearAlerts();
            this.toggleModal("openJoinChannelModal", false);
          }}
          aria-labelledby="join-channel-modal-title"
          aria-describedby="join-channel-modal-description"
        >
          <div className={classes.modalStyle}>
            <h2 id="join-channel-modal-title" style={{ color: "#7fa9ff" }}>
              Join Channel Parameters
            </h2>
            <TextField
              label="ChannelName"
              variant="outlined"
              onChange={this.handleInputChange("joinChannelName")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="OrgName"
              variant="outlined"
              onChange={this.handleInputChange("joinOrgName")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Peer1"
              variant="outlined"
              onChange={this.handleInputChange("joinPeer1")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Peer2"
              variant="outlined"
              onChange={this.handleInputChange("joinPeer2")}
              fullWidth
              margin="normal"
            />
            <ButtonAkb
              onClick={this.runJoinChannelScript}
              style={{ marginTop: "20px" }}
            >
              Run Script
            </ButtonAkb>
            {this.renderAlerts()}
          </div>
        </Modal>
        <Modal
          open={this.state.openAddNodeModal}
          onClose={() => {
            this.clearAlerts();
            this.toggleModal("openAddNodeModal", false);
          }}
          aria-labelledby="add-node-modal-title"
          aria-describedby="add-node-modal-description"
        >
          <div className={classes.modalStyle}>
            <h2 id="add-node-modal-title" style={{ color: "#7fa9ff" }}>
              Add New Node Parameters
            </h2>
            <TextField
              label="OrgName"
              variant="outlined"
              onChange={this.handleInputChange("newNodeOrgName")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="NodeName"
              variant="outlined"
              onChange={this.handleInputChange("newNodeName")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="NodePort"
              variant="outlined"
              onChange={this.handleInputChange("newNodePort")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="CcPort"
              variant="outlined"
              onChange={this.handleInputChange("newCcPort")}
              fullWidth
              margin="normal"
            />
            <ButtonAkb
              onClick={this.runAddNodeScript}
              style={{ marginTop: "20px" }}
            >
              Run Script
            </ButtonAkb>
            {this.renderAlerts()}
          </div>
        </Modal>
        {/* ================== Deploy chaincode ================== */}
        <h1 className={classes.display} style={{ color: "#5170d4" }}>
          Deploy Chaincode
        </h1>
        <ViewTable style={{ height: 100 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Channel Name"
              variant="outlined"
              onChange={this.handleInputChange("mainChannelName")}
              style={{ flex: 1, margin: "10px", maxWidth: "200px" }}
            />
            <ButtonAkb onClick={() => this.toggleModal("openCCParams", true)}>
              Update Custom Chaincode Parameters
            </ButtonAkb>
            <Modal
              open={this.state.openCCParams}
              onClose={() => {
                this.clearAlerts();
                this.toggleModal("openCCParams", false);
              }}
              aria-labelledby="join-channel-modal-title"
              aria-describedby="join-channel-modal-description"
            >
              <div className={classes.modalStyle}>
                <h2 id="join-channel-modal-title" style={{ color: "#7fa9ff" }}>
                  Set Custom Chaincode Parameters
                </h2>
                <TextField
                  label="CC_NAME"
                  variant="outlined"
                  onChange={(e) => this.setReqBodyField("CC_NAME", e)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="CC_VERSION"
                  variant="outlined"
                  onChange={(e) => this.setReqBodyField("CC_VERSION", e)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="CC_SRC_PATH"
                  variant="outlined"
                  onChange={(e) => this.setReqBodyField("CC_SRC_PATH", e)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="INIT_FUNCTION_NAME"
                  variant="outlined"
                  onChange={(e) =>
                    this.setReqBodyField("INIT_FUNCTION_NAME", e)
                  }
                  fullWidth
                  margin="normal"
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Checkbox
                    checked={this.state.reqBody.INIT_REQUIRED}
                    onChange={(e) => this.setReqBodyField("INIT_REQUIRED", e)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span>INIT_REQUIRED</span>
                </div>
                <ButtonAkb
                  onClick={this.setChainCodeParameters}
                  style={{ marginTop: "20px" }}
                >
                  Update Parameters
                </ButtonAkb>
                {this.renderAlerts()}
              </div>
            </Modal>
            <ButtonAkb
              onClick={this.runChaicodeMain}
              style={{ marginTop: "20px" }}
            >
              Run Script
            </ButtonAkb>
          </div>
        </ViewTable>
        {/* ================== Create New User ================== */}
        <h1 className={classes.display} style={{ color: "#5170d4" }}>
          Create New User
        </h1>
        <ViewTable>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <TextField
              id="outlined-userId"
              label="User ID"
              variant="outlined"
              value={this.state.selectedUser}
              onChange={(event) =>
                this.setState({ selectedUser: event.target.value })
              }
              style={{ flex: 1, margin: "10px", maxWidth: "200px" }}
            />
            <TextField
              id="outlined-orgName"
              label="Org Name"
              variant="outlined"
              value={this.state.orgName}
              onChange={(event) =>
                this.setState({ orgName: event.target.value })
              }
              style={{ width: 300, margin: "10px" }}
            />
            <TextField
              id="outlined-orgName"
              label="Ca Name"
              variant="outlined"
              value={this.state.caNameCu}
              onChange={(event) =>
                this.setState({ caNameCu: event.target.value })
              }
              style={{ width: 300, margin: "10px" }}
            />

            <ButtonAkb onClick={this.createUser}>Add User</ButtonAkb>
          </div>
        </ViewTable>
        {/* ================== Shutdown network ================== */}
        <h1 className={classes.display} style={{ color: "#5170d4" }}>
          Shutdown network
        </h1>
        <Alert className={classes.display} severity="info">
          Available options : "down" and "clear". They both shutdown the network
          but clear will also delete all the executed run time operation such as
          adding new org or channel
        </Alert>
        <ViewTable>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="operation-selectshutdown-label" shrink={true}>
                Select Shutdown Mode
              </InputLabel>
              <Select
                labelId="operation-selectshutdown-label"
                id="operation-selectshutdown"
                value={this.state.networkMode}
                onChange={this.handleInputChange("networkMode")}
                label="Select Shutdown Mode"
              >
                <MenuItem value="down">Down</MenuItem>
                <MenuItem value="clear">Clear</MenuItem>
              </Select>
            </FormControl>
            <ButtonAkb onClick={this.runNetworkMode}>Shut down</ButtonAkb>
          </div>
        </ViewTable>
      </div>
    );
  }
}

InvokeView.propTypes = {
  transaction: transactionType,
};

InvokeView.defaultProps = {
  transaction: null,
};

export default withStyles(styles)(InvokeView);
