/**
 *    SPDX-License-Identifier: Apache-2.0
 */

//import React from 'react';
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import InvokeView from "./View/InvokeView";
import BlocksView from "./View/BlocksView";
import NetworkView from "./View/NetworkView";
import TransactionsView from "./View/TransactionsView";
import ChaincodeView from "./View/ChaincodeView";
import DashboardView from "./View/DashboardView";
import ChannelsView from "./View/ChannelsView";
import { chartSelectors } from "../state/redux/charts";
import { tableOperations, tableSelectors } from "../state/redux/tables";
import {
  blockListType,
  chaincodeListType,
  channelsType,
  currentChannelType,
  dashStatsType,
  getTransactionType,
  peerListType,
  peerStatusType,
  transactionType,
  transactionByOrgType,
  transactionListType,
} from "./types";
import PageNotFound from "./View/PageNotFound";

import Private from "./Route";
import { stringify } from "querystring";

const {
  currentChannelSelector,
  blockActivitySelector,
  channelListSelector,
  dashStatsSelector,
  peerStatusSelector,
  transactionByOrgSelector,
} = chartSelectors;

const {
  blockListSelector,
  chaincodeListSelector,
  channelsSelector,
  peerListSelector,
  transactionSelector,
  transactionListSelector,
  blockListSearchSelector,
  transactionListSearchSelector,
} = tableSelectors;

const styles = (theme) => {
  const { type } = theme.palette;
  const dark = type === "dark";
  return {
    main: {
      color: dark ? "#ffffff" : undefined,
    },
  };
};

export const Main = (props) => {
  const {
    classes,
    blockList,
    blockActivity,
    chaincodeList,
    channels,
    currentChannel,
    dashStats,
    getTransaction,
    peerList,
    peerStatus,
    transaction,
    transactionByOrg,
    transactionList,
    blockListSearch,
    transactionListSearch,
    getBlockListSearch,
    getTransactionListSearch,
  } = props;

  //console.log("props = " + JSON.stringify(props));

  const blocksViewProps = {
    blockList,
    blockListSearch,
    getBlockListSearch,
    transactionByOrg,
    currentChannel,
    getTransaction,
    transaction,
  };
  const chaincodeViewProps = {
    chaincodeList,
  };

  const channelsViewProps = {
    channels,
  };

  const dashboardViewProps = {
    blockList,
    dashStats,
    peerStatus,
    transactionByOrg,
    blockActivity,
  };

  const networkViewProps = {
    peerList,
  };

  const invokeViewProps = {
    currentChannel,
    transaction,
    transactionList,
    getTransaction,
    transactionByOrg,
    transactionListSearch,
    getTransactionListSearch,
  };
  const transactionsViewProps = {
    currentChannel,
    transaction,
    transactionList,
    getTransaction,
    transactionByOrg,
    transactionListSearch,
    getTransactionListSearch,
  };

  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    let windowUrl = window.location.search;
    let queryParams = new URLSearchParams(windowUrl);
    if (queryParams.get("tab")) {
      setTransactionId(queryParams.get("transId"));
      const { history } = props;
      let routePath = "/" + queryParams.get("tab");
      history.replace(routePath);
    }
  }, []);

  function removeTransactionId() {
    let windowUrl = window.location.search;
    let queryParams = new URLSearchParams(windowUrl);
    if (queryParams.get("tab")) {
      queryParams.delete("tab");
      queryParams.delete("transId");
    }
    setTransactionId("");
  }

  return (
    <Router basename="/explorer">
      <div className={classes.main}>
        <Switch>
          <Private
            exact
            path="/"
            render={(routeprops) => (
              <DashboardView {...{ ...dashboardViewProps, ...routeprops }} />
            )}
          />
          <Private
            exact
            path="/blocks"
            render={(routeprops) => (
              <BlocksView {...{ ...blocksViewProps, ...routeprops }} />
            )}
          />
          <Private
            exact
            path="/chaincodes"
            render={(routeprops) => (
              <ChaincodeView {...{ ...chaincodeViewProps, ...routeprops }} />
            )}
          />
          <Private
            exact
            path="/channels"
            render={(routeprops) => (
              <ChannelsView {...{ ...channelsViewProps, ...routeprops }} />
            )}
          />
          <Private
            exact
            path="/network"
            render={(routeprops) => (
              <NetworkView {...{ ...networkViewProps, ...routeprops }} />
            )}
          />
          <Private
            exact
            path="/invoke"
            render={(routeprops) => (
              <InvokeView
                {...{ ...invokeViewProps, ...routeprops }}
                transactionId={transactionId}
                removeTransactionId={removeTransactionId}
              />
            )}
          />
          <Private
            exact
            path="/transactions"
            render={(routeprops) => (
              <TransactionsView
                {...{ ...transactionsViewProps, ...routeprops }}
                transactionId={transactionId}
                removeTransactionId={removeTransactionId}
              />
            )}
          />
          <Route
            exact
            render={(routeprops) => <PageNotFound {...routeprops} />}
          />
        </Switch>
      </div>
    </Router>
  );
};

Main.propTypes = {
  blockList: blockListType.isRequired,
  chaincodeList: chaincodeListType.isRequired,
  channels: channelsType.isRequired,
  currentChannel: currentChannelType.isRequired,
  dashStats: dashStatsType.isRequired,
  getTransaction: getTransactionType.isRequired,
  peerList: peerListType.isRequired,
  peerStatus: peerStatusType.isRequired,
  transaction: transactionType.isRequired,
  transactionByOrg: transactionByOrgType.isRequired,
  transactionList: transactionListType.isRequired,
};

const connectedComponent = connect(
  (state) => ({
    blockList: blockListSelector(state),
    chaincodeList: chaincodeListSelector(state),
    channelList: channelListSelector(state),
    channels: channelsSelector(state),
    currentChannel: currentChannelSelector(state),
    dashStats: dashStatsSelector(state),
    peerList: peerListSelector(state),
    peerStatus: peerStatusSelector(state),
    transaction: transactionSelector(state),
    transactionByOrg: transactionByOrgSelector(state),
    transactionList: transactionListSelector(state),
    blockListSearch: blockListSearchSelector(state),
    transactionListSearch: transactionListSearchSelector(state),
    blockActivity: blockActivitySelector(state),
  }),
  {
    getTransaction: tableOperations.transaction,
    getBlockListSearch: tableOperations.blockListSearch,
    getTransactionListSearch: tableOperations.transactionListSearch,
  }
)(Main);
export default withStyles(styles)(connectedComponent);
